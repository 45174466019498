import React from "react"
import Footer from "../components/footer/footer"
import NavigationBar, { NavBarBg } from "../components/navbar/navigationBar"
import BackgroundContainer from "../components/homepage/backgroundContainer/backgroundContainer"
import Contact from "../components/contact/contact"
import SEO from "../components/head/seo"
const ContactUs = () => {
  return (
    <>
    <SEO title="Contact Petrachor" desc="Contact Petrachor or stay in touch with the community by email, Github or Telegram." />
    <div class="contact-container">
      <BackgroundContainer>
        <NavigationBar />
        <Contact />
      </BackgroundContainer>
      <Footer />
    </div>
    </>
  )
}

export default ContactUs
