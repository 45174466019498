import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button, Col, Container, Row, Image } from "react-bootstrap"
import ContactButtons from "./contactButtons"
import "./contact.less"
import EmailForm from "../emailForm"
const Contact = () => {
  const data = useStaticQuery(graphql`
    query contactsQuery {
      strapiContactUs {
        Header
        Description
        ButtonsHeader
        ButtonsDescription
        ContactButtons {
          id
          Text
          Link
          ColorCode
          Logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <div className="contacts-container">
      <Container>
        <Row className="my-5">
          <Col sm={12}>
            <h1 className="display-1">{data.strapiContactUs?.Header}</h1>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md={6}>
            <p className="h6">{data?.strapiContactUs?.Description} </p>
          </Col>
          <Col md={6}>
            <EmailForm className="email-form" />{" "}
          </Col>
        </Row>
        <ContactButtons />
      </Container>
    </div>
  )
}

export default Contact
